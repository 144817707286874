import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.div`
  padding: 75px 0;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding: 75px 0 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 0 25px;
  }
  .container {
    .blockTitle {
      text-align: center;
      margin-bottom: 0px;
        font-weight: 700;
      @media (max-width: 625px) {
        margin-bottom: 45px;
      }

      h2,
      p {
        margin: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 800;
        font-size: 44px;
        line-height: 1.15;
        text-align: center;
        letter-spacing: -0.03em;
        color: #40516d;

        @media (max-width: 375px) {
          font-size: 22px;
        }
      }
      p {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 1.32;
        color: #849aa8;
        margin-top: 15px;
      }
    }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  	  background-image: linear-gradient(90deg, #fff 0%, #DEEFF6 100%);
	  padding: 30px;
	  border-radius: 15px;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -33px;
      margin-right: -33px;

      @media (max-width: 1199px) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    .column {
      flex: 1 1 33.333%;
      padding-left: 33px;
      padding-right: 33px;
      @media (max-width: 1199px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: 991px) {
        flex: 1 1 50%;
      }

      @media (max-width: 625px) {
        flex: 1 1 100%;
      }
    }
  }
  .image {
    width: 50%;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    @media only screen and (max-width: 480px) {
    }
    img {
      width: 100%;
      object-fit: contain;
      padding: 30px;
    }
  }
  .content {
    width: 50%;
    padding-left: 10px;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 50px;
      padding-left: 0px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 0px;
    }
    h2 {
      color: #40516d;
      font-size: 48px;
      line-height: 1.2;
      font-weight: 800;
      letter-spacing: 0em;
      margin-bottom: 27px;
      @media only screen and (max-width: 1440px) {
        margin-bottom: 15px;
        font-size: 38px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 40px;
        max-width: 100%;
        text-align: left;
      }
      @media only screen and (max-width: 480px) {
        font-size: 30px;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #496b96;
      max-width: 400px;
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        text-align: left;
      }
    }
    .walletfeatures {
      margin-top: 40px;
      img {
        height: 24px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: #111;
        padding-left: 24px;
        max-width: 100%;
        margin-bottom: 30px;
        margin-top: -1px;
      }
    }
    .btnGroups {
      margin-top: 30px;
      margin-bottom: 20px;
      .reusecore__button {
        font-weight: 700;
        color: #496b96;
        border: 1px solid #CDD4DC;
        background: #fff;
        border-radius: 10px;
        margin-right: 17px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 14px;
        text-transform: uppercase;
        @media only screen and (max-width: 480px) {
          width: 100%;
          &:nth-child(1) {
            margin-bottom: 15px;
          }
        }
        .btn-icon {
          margin-right: 10px;
          margin-top: -2px;
          img {
            height: 18px;
          }
        }
      }
    }
    .windowsAllert {
      font-size: 14px;
    }
  }
`;

export default SectionWrapper;
