
import styled, { keyframes } from 'styled-components';
//import BannerBG from 'common/assets/image/saasModern/banner-texture.png';
const Bubble = keyframes`
  0% {
		box-shadow: 0 0 0 3px rgba(255,65,108,0.3);
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		box-shadow: 0 0 0 100px rgba(255,65,108,0);
		opacity: 0;
		transform: scale3d(1, 1, 1);
	}
`;

export const BannerWrapper = styled.section`
  position: relative;
  width: 100%;
  padding-top: 160px;
  background-size: cover;

  linear-gradient( 130.92deg,#e6e6e6 -14.59%,#ffffff 38.47%,#f2f5f8 98.62%
  );
    background-size: cover;
  background-position: top center;
  margin-bottom: 135px;

  @media (max-width: 767px) {
    padding-top: 110px;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
    padding-bottom: 80px;
  }
    img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      display: block;
      color: #;
      opacity: 0.6;
      font-size: 15px;
      /*margin-top: 15px;*/
    }
  }
  h3 {
    margin: 0;
    color: #849aa8;
    font-style: normal;
    font-weight: 800;
    font-size: 62px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    white-space: pre-line;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.2;
    }

    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 1.2;
    }

    @media (max-width: 690px) {
      font-size: 40px;
      line-height: 1.2;
      width: 70%;
      white-space: normal;
    }

    @media (max-width: 575px) {
      font-size: 35px;
      line-height: 1.2em;
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }
  p {
    margin: 0;
    color: #1395df;
    opacity: 0.8;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.88;
    letter-spacing: -0.3px;
    width: 42%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      width: 48%;
    }

    @media (max-width: 690px) {
      width: 55%;
    }

    @media (max-width: 575px) {
      width: 65%;
      line-height: 1.5;
    }

    @media (max-width: 425px) {
      width: 80%;
    }
  }
  .imageWrapper {
    display: inline-block;
    position: relative;
    bottom: -135px;
    margin-top: -115px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.15);

    @media (max-width: 480px) {
      bottom: 0;
      margin-top: 40px;
    }
  }
  .reusecore__button {
    background-color: #0B6DF1;
    padding: 17.5px 20px;
    border-radius: 5px;
    transition: 500ms;
    position: relative;
    z-index: 10;

    &:hover {
      color: #0078D7;
      background-color: #0078D7;
    }
    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
  }
`;

export const Features = styled.ul`
  display: flex;
  justify-content: center;
  
  @media only screen and (max-width: 480px) {
    display: block;
    margin-top: 10px;
  }
  li {
    padding-top: 10px;
    font-weight: 400;
    font-size: 15px;
    color: #8e9aa3;
    margin-right: 18px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    i {
      display: inline-flex !important;
      margin-right: 8px;
      color: #8e9aa3;
    }
    @media only screen and (max-width: 480px) {
      line-height: 1.8;
    }
  }
`;



export const VideoModal = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      -139deg,
      #d2dde8 0%,
      #09142e 100%
    );
    opacity: 0.1;
    pointer-events: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const VideoModal2 = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      337deg,
      #d2dde8 0%,
      #09142e, 43) 100%
    );
    opacity: 0.1;
    pointer-events: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const PlayButton = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #b1becc;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
  outline: 0;
  z-index: 2;
  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
    svg {
      width: 30px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    top: 0;
    left: 0;
    border-radius: inherit;
  }
  &:focus {
    &:after {
      animation: ${Bubble} 0.5s ease-out forwards;
    }
  }
`;

export const VideoWrapper = styled.div`
  max-width: 100%;
  width: 1240px;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
