import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Heading from 'common/components/Heading';
import Icon from 'react-icons-kit';
import { check } from 'react-icons-kit/feather/check';
import { trendingUp } from 'react-icons-kit/feather/trendingup';
//import { shield } from 'react-icons-kit/feather/shield';
import { lock } from 'react-icons-kit/feather/lock';
import Text from 'common/components/Text';
//import Button from 'common/components/Button';

import Container from 'common/components/UI/Container';

//import TiltShape from '../TiltShape';
//import Parent from './Parent'
//import TextHighlight from './TextHighlite'
//import TiltShape from '../TiltShape';
//import Parent from './Parent'
//import TextHighlight from './TextHighlite'

import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import { BannerWrapper, Features } from './bannerSection.style';



  // DELAY
export const Parent = loadable(() =>
pMinDelay(import(/* webpackPrefetch: true */ './Parent'), 5600)
)

const PayCare = loadable(() => import('./PayCare'))


// no delay
//const Parent = loadable(() =>
//  import(/* webpackPrefetch: true */ './Parent'),
//)

//SIMPLE
//const PayCare = loadable(() => import('./PayCare'))



  





const BannerSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      sassMinimalJson {
        BANNER_DATA {
          title
          text
          image {
            publicURL
          }
          btnLabel
          btnLink
          offerText
        }
      }
    }
  `);
  return (
    <BannerWrapper id="banner_section">

      {Data.sassMinimalJson.BANNER_DATA.map((banner, index) => (
        <Container key={`banner-${index}`}>

<Heading content={banner.title} as="h3" />
<Text content={banner.text} />
      
          <Link rel="prefetch" to={banner.btnLink}>

          </Link>
<PayCare />
<Features>
<li>
                <Icon size={20} icon={lock} style={{ color: '#E9C46A' }} />
                 We Care about Your Privacy as much as you do
              </li>
              </Features>
          <Features>
              <li>
                <Icon size={20} icon={check} /> Trade with escrow risk-free Protection
              </li>
              <li>
                <Icon size={25} icon={trendingUp} /> Smart contract protocol
              </li>
              <li>
                <Icon size={20} icon={check} /> Global payment system
              </li>
            </Features>


    <Parent />



        </Container>
      ))}
      ;

    </BannerWrapper>
  );
};

export default BannerSection;
