import React from 'react';
import { Icon } from 'react-icons-kit';
import { check } from 'react-icons-kit/feather/check';
import { Link } from 'gatsby';
import { arrowRight } from 'react-icons-kit/feather/arrowRight';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
//import Input from 'common/components/Input';
import {
  Section,
  ContentWrapper,
  SubscriptionWrapper,
  SubscriptionForm,
  Features,
} from './subscription.style';

const Subscription = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('subscribed.');
  };
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <SubscriptionWrapper>
		  <h2 class="htop">Protect My Money</h2>
            <Heading content="in 3 easy step!" />
            
            <Link rel="prefetch" to="/investment">
            <SubscriptionForm onSubmit={handleSubmit}>
              <Button
                type="submit"
                title="Start Investing Now"
                icon={<Icon size={18} icon={arrowRight} />}
              />
              </SubscriptionForm>
            </Link>
            
            <Features>
              <li>
                <Icon size={20} icon={check} /> 30 days money back
              </li>
              <li>
                <Icon size={20} icon={check} /> Cancel anytime
              </li>
              <li>
                <Icon size={20} icon={check} /> Support &amp; help
              </li>
            </Features>
          </SubscriptionWrapper>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Subscription;
