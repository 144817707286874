import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.section`
  padding: 0px 0;
  overflow: hidden;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto -30px;
  @media only screen and (max-width: 1366px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 480px) {
    margin: 0;
  }

  > div {
    width: calc(100% / 3 - 27px);
    margin: 15px 0 30px;
    @media only screen and (max-width: 991px) {
      width: calc(100% / 3 - 15px);
    }
    @media only screen and (max-width: 667px) {
      width: calc(100% - 27px);
      &:first-child {
        margin-top: 10;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .feature__block {
    text-align: center;
    .content__wrapper {
      max-width: 330px;
      margin: 0 auto;
      h3 {
        color: #40516d;
        font-size: 27px;
        line-height: 28px;
        font-weight: 800;
        margin-bottom: 15px;
      }
      p {
        color: #849aa8;
        font-size: 14px;
		font-weight: 600;
        line-height: 26px;
        padding: 0 10px;
        margin: 0;
      }
    }
  }
`;

export default SectionWrapper;
